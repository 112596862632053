import { createReducer, on } from '@ngrx/store';
import { setCompany } from './company.actions';

export const initialState = '';

const _companyReducer = createReducer(
  initialState,
  on(setCompany, (state, { companyId }) => companyId)
);

export function companyReducer(state, action) {
  return _companyReducer(state, action);
}
