import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbThemeModule, NbLayoutModule, NbDatepickerModule, NbMenuModule, NbDialogModule } from '@nebular/theme';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { StoreModule } from '@ngrx/store';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { HttpRequestInterceptor } from './_helpers/http-request.interceptor';
import { HttpXsrfInterceptor } from './_helpers/http-xsrf.interceptor';
import { campaignReducer } from './state/campaign.reducer';
import { companyReducer } from './state/company.reducer';
import { accountReducer } from './state/account.reducer';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';

@NgModule({
  declarations: [AppComponent],
  
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'hitarget' }),
    NbDatepickerModule.forRoot(),
    NbLayoutModule,
    NgxSpinnerModule,
    NbDialogModule.forRoot(),
    NbMenuModule.forRoot(),
    ToastrModule.forRoot(),
    StoreModule.forRoot({ campaignId: campaignReducer, companyId: companyReducer, accountId: accountReducer })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
