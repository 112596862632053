import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  constructor(private http: HttpClient) {}

  getCsrf() {
    return this.http.get<any>(`${environment.apiUrl}/sanctum/csrf-cookie`);
  }

  login(email: string, password: string) {
    return this.http.post<any>(`${environment.apiUrl}/login`, { email, password }, { withCredentials: true });
  }

  logout() {
    return this.http.post<any>(`${environment.apiUrl}/logout`, { withCredentials: true });
  }
}
