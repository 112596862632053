import { Component } from '@angular/core';
import { NbIconLibraries } from '@nebular/theme';

import * as config from 'src/assets/config.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  today = new Date();
  companyName = document.title;

  constructor(private iconLibraries: NbIconLibraries) {
    const icons = config['icons'];
    this.iconLibraries.registerSvgPack('custom', icons);
    this.iconLibraries.setDefaultPack('custom');
  }
}
