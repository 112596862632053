import { createReducer, on } from '@ngrx/store';

import { setCampaign } from './campaign.actions';

export const initialState = '';

const _campaignReducer = createReducer(
  initialState,
  on(setCampaign, (state, { campaignId }) => campaignId)
);

export function campaignReducer(state, action) {
  return _campaignReducer(state, action);
}
