<nb-layout>
  <nb-layout-column>
    <router-outlet></router-outlet>
  </nb-layout-column>
  <nb-layout-footer>
    <div class="footer-item">
      <p class="text"></p>
    </div>
  </nb-layout-footer>
</nb-layout>
<ngx-spinner></ngx-spinner>
