import { createReducer, on } from '@ngrx/store';
import { setAccount } from './account.actions';

export const initialState = '';

const _accountReducer = createReducer(
  initialState,
  on(setAccount, (state, { accountId }) => accountId)
);

export function accountReducer(state, action) {
  return _accountReducer(state, action);
}
